import React from "react";
import team1 from "../images/team.jpg";
import team2 from "../images/team2.jpg";
import team3 from "../images/team3.jpg";
import team4 from "../images/team4.jpg";
import team5 from "../images/team5.jpg";
import team6 from "../images/team6.jpg";
import team7 from "../images/team7.jpg";
import team8 from "../images/team8.jpg";
import team9 from "../images/team9.jpg";
import team10 from "../images/team10.jpg";
import team11 from "../images/team11.jpg";
import team12 from "../images/team12.jpg";
import team13 from "../images/team13.jpg";
// import team14 from "../images/team14.jpg";
import team15 from "../images/team15.jpg";
import team16 from "../images/team16.jpg";
// import team17 from "../images/team17.jpg";
import team18 from "../images/team18.jpg";
import team19 from "../images/team19.jpg";
import team20 from "../images/team20.jpg";
const Team = () => {
  return (
    <section className="team-area team-area2 text-center">
      <div className="container">
        <div className="row team-content-wrap">
          <div className="col-lg-3 col-sm-6">
            <div className="team-item team-item1">
              <div className="team__img">
                <img src={team1} alt="team post" />
              </div>
              <div className="team__title">
                <h3 className="team__title-title">
                  <a href="/volunteer/">Khageswar Singha</a>
                </h3>
                <span className="team__title-meta">General Secretary</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="team-item team-item4">
              <div className="team__img">
                <img src={team2} alt="team post" />
              </div>
              <div className="team__title">
                <h3 className="team__title-title">
                  <a href="/volunteer/">Sanjib Saha</a>
                </h3>
                <span className="team__title-meta">Joint Secretary</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="team-item team-item3">
              <div className="team__img">
                <img src={team4} alt="team post" />
              </div>
              <div className="team__title">
                <h3 className="team__title-title">
                  <a href="/volunteer/">Sanjit Singha</a>
                </h3>
                <span className="team__title-meta">Assistant Secretary</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="team-item team-item2">
              <div className="team__img">
                <img src={team3} alt="team post" />
              </div>
              <div className="team__title">
                <h3 className="team__title-title">
                  <a href="/volunteer/">Saduram Singha</a>
                </h3>
                <span className="team__title-meta">President</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row team-content-wrap">
          <div className="col-lg-3 col-sm-6">
            <div className="team-item team-item1">
              <div className="team__img">
                <img src={team5} alt="team post" />
              </div>
              <div className="team__title">
                <h3 className="team__title-title">
                  <a href="/volunteer/">Ganesh Rajbanshi</a>
                </h3>
                <span className="team__title-meta">Vice President</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="team-item team-item2">
              <div className="team__img">
                <img src={team6} alt="team post" />
              </div>
              <div className="team__title">
                <h3 className="team__title-title">
                  <a href="/volunteer/">Swapan Singha</a>
                </h3>
                <span className="team__title-meta">Cashier</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="team-item team-item3">
              <div className="team__img">
                <img src={team7} alt="team post" />
              </div>
              <div className="team__title">
                <h3 className="team__title-title">
                  <a href="/volunteer/">Samar Kundu</a>
                </h3>
                <span className="team__title-meta">Member</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="team-item team-item4">
              <div className="team__img">
                <img src={team8} alt="team post" />
              </div>
              <div className="team__title">
                <h3 className="team__title-title">
                  <a href="/volunteer/">Kedar Nath Sinha</a>
                </h3>
                <span className="team__title-meta">Member</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row team-content-wrap">
          <div className="col-lg-3 col-sm-6">
            <div className="team-item team-item1">
              <div className="team__img">
                <img src={team9} alt="team post" />
              </div>
              <div className="team__title">
                <h3 className="team__title-title">
                  <a href="/volunteer/">Pritish Roy</a>
                </h3>
                <span className="team__title-meta">Member</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="team-item team-item2">
              <div className="team__img">
                <img src={team10} alt="team post" />
              </div>
              <div className="team__title">
                <h3 className="team__title-title">
                  <a href="/volunteer/">Prafulla Ch. Singha</a>
                </h3>
                <span className="team__title-meta">Member</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="team-item team-item3">
              <div className="team__img">
                <img src={team11} alt="team post" />
              </div>
              <div className="team__title">
                <h3 className="team__title-title">
                  <a href="/volunteer/">Bhabatosh Roy</a>
                </h3>
                <span className="team__title-meta">Member</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="team-item team-item4">
              <div className="team__img">
                <img src={team12} alt="team post" />
              </div>
              <div className="team__title">
                <h3 className="team__title-title">
                  <a href="/volunteer/">Girilal Singha</a>
                </h3>
                <span className="team__title-meta">Member</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row team-content-wrap">
          <div className="col-lg-3 col-sm-6">
            <div className="team-item team-item1">
              <div className="team__img">
                <img src={team13} alt="team post" />
              </div>
              <div className="team__title">
                <h3 className="team__title-title">
                  <a href="/volunteer/">Dipak Singha</a>
                </h3>
                <span className="team__title-meta">Member</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="team-item team-item2">
              <div className="team__img">
                <img src={team19} alt="team post" />
              </div>
              <div className="team__title">
                <h3 className="team__title-title">
                  <a href="/volunteer/">Bhaskar Singha</a>
                </h3>
                <span className="team__title-meta">Member</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="team-item team-item3">
              <div className="team__img">
                <img src={team15} alt="team post" />
              </div>
              <div className="team__title">
                <h3 className="team__title-title">
                  <a href="/volunteer/">Santosh Singha</a>
                </h3>
                <span className="team__title-meta">Member</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="team-item team-item4">
              <div className="team__img">
                <img src={team16} alt="team post" />
              </div>
              <div className="team__title">
                <h3 className="team__title-title">
                  <a href="/volunteer/">Tarun Sinha</a>
                </h3>
                <span className="team__title-meta">Member</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row team-content-wrap">
          <div className="col-lg-3 col-sm-6">
            <div className="team-item team-item1">
              <div className="team__img">
                <img src={team18} alt="team post" />
              </div>
              <div className="team__title">
                <h3 className="team__title-title">
                  <a href="/volunteer/">Dipak Singha</a>
                </h3>
                <span className="team__title-meta">Member</span>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-3 col-sm-6">
            <div className="team-item team-item2">
              <div className="team__img">
                <img src={team19} alt="team post" />
              </div>
              <div className="team__title">
                <h3 className="team__title-title">
                  <a href="/volunteer/">Bhaskar Singha</a>
                </h3>
                <span className="team__title-meta">Member</span>
              </div>
            </div>
          </div> */}
          <div className="col-lg-3 col-sm-6">
            <div className="team-item team-item3">
              <div className="team__img">
                <img src={team20} alt="team post" />
              </div>
              <div className="team__title">
                <h3 className="team__title-title">
                  <a href="/volunteer/">Santosh Singha</a>
                </h3>
                <span className="team__title-meta">Member</span>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-3 col-sm-6">
            <div className="team-item team-item4">
              <div className="team__img">
                <img src={team16} alt="team post" />
              </div>
              <div className="team__title">
                <h3 className="team__title-title">
                  <a href="/volunteer/">Tarun Sinha</a>
                </h3>
                <span className="team__title-meta">Member</span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Team;
